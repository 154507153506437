import React, { useContext, useState, memo, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { useGoogleLogin } from '@react-oauth/google'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { SVG } from '../../../assets'
import { connect } from 'react-redux'
import { setGoogleToken } from '../../../services/api-services/api-services'
import { login, loginWithGoogle } from '../../../actions/auth.action'
import InputComponent from '../../../components/InputComponent/InputComponent'
import Copyright from '../../../components/Copyright/Copyright'
import {
  Container,
  FormAndCopyrightContainer,
  FormAndTitleContainer,
  TitleContainer,
  FormContainer,
  FormHeadingContainer,
  FormSubHeadingText,
  DividerContainer,
  Divider1,
  Divider2,
  OrLoginWithContainer,
  DesignContainer,
  AuthDesignImage,
  GoogleSignUpButton,
  ContinueWithText,
  GoogleIcon,
} from '../AuthFlow.styled'
import {
  LoginRememberMeAndForgetPasswordContainer,
  LoginRememberMeContainer,
  LoginForgetPasswordLink,
  LoginLoginButtonContainer,
  LoginLoginButton,
  LoginDontHaveAccountContainer,
  LoginSignUpLink,
  InputPlaceholder,
} from './loginPage.styled'
import { UserLoginContext } from '../../../utils/Context/LoggedInUserContext'

const mapStateToProps = (state) => ({
  isAuthenticated: state.authReducer.isAuthenticated,
})

const LoginPage = (props) => {
  const { login, isAuthenticated } = props
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })
  const [rememberMe, setRememberMe] = useState(false)
  const [secureTextEntry, setSecureTextEntry] = useState(true)
  const { setLoggedInUserValues } = useContext(UserLoginContext)
  const navigate = useNavigate()

  const googleSignIn = useGoogleLogin({
    clientId: '464876682696-pkm7moinvftntbnild9dq19378vu3ski.apps.googleusercontent.com',
    onSuccess: (response) => {
      console.log(response)
      const token = response.access_token
      setGoogleToken(token)

      // Send the token to your backend for verification and user data fetching
      fetch('https://api.travmigoz.com/login/googleLogin', {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'googletoken': `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (!response.ok) {
            return response.json().then((error) => {
              throw new Error(error)
            })
          }
          return response.json()
        })
        .then((data) => {
          console.log('the data is', data)
          if (data.success) {
            console.log('Login successful:', data)
            const previousURL = sessionStorage.getItem('redirectUrl') || '/'
            sessionStorage.removeItem('redirectUrl')
            // Handle successful login on frontend if needed
            console.log(previousURL)
            navigate(previousURL)
            setLoggedInUserValues(data.user)
          } else {
            console.error('Login failed:', data)
            setLoggedInUserValues({
              _id: '',
              username: '',
              emailId: '',
              profilePic: '',
            })
          }
        })
        .catch((error) => {
          console.error('Error during login:', error)
          setLoggedInUserValues({
            _id: '',
            username: '',
            emailId: '',
            profilePic: '',
          })
        })
    },
    onError: (error) => {
      console.error('Login failed:', error)
      setLoggedInUserValues({
        _id: '',
        username: '',
        emailId: '',
        profilePic: '',
      })
    },
  })

  const toggleRemeberMeCheckbox = () => {
    setRememberMe((prevState) => !prevState)
  }

  const checkValueIsValid = useCallback((value) => {
    if (value === '' || value === undefined || value === null) {
      return false
    }
    return true
  }, [])

  const handleLogin = async (e) => {
    e.preventDefault()

    const validEmail = checkValueIsValid(formData.email)
    const validPassword = checkValueIsValid(formData.password)

    if (!(validEmail && validPassword)) {
      toast.error('Email-id or Password is not valid!', {
        autoClose: 1500,
      })
      return
    }
    const isAuth = await login(formData.email, formData.password, rememberMe)

    if (isAuth) {
      navigate('/')
    }
  }

  return (
    <Container>
      <FormAndCopyrightContainer>
        <FormAndTitleContainer>
          <TitleContainer>Travmigoz</TitleContainer>
          <FormContainer>
            <FormHeadingContainer>Login</FormHeadingContainer>
            <FormSubHeadingText>Login to access your account</FormSubHeadingText>

            <form onSubmit={handleLogin}>
              <InputComponent
                label="Email"
                type="email"
                name="email"
                id="email"
                placeholder="Enter Your Email"
                user={formData}
                setUser={setFormData}
              />

              <InputComponent
                label="Password"
                type={secureTextEntry ? 'password' : 'text'}
                name="password"
                id="password"
                user={formData}
                setUser={setFormData}
                placeholder="Enter Your Password"
                isPasswordField={true}
                secureTextState={secureTextEntry}
                setSecureTextState={setSecureTextEntry}
              />

              <LoginRememberMeAndForgetPasswordContainer>
                <LoginRememberMeContainer>
                  <input type="checkbox" checked={rememberMe} onClick={toggleRemeberMeCheckbox} />
                  <div>Remember Me</div>
                </LoginRememberMeContainer>

                <LoginForgetPasswordLink href="/forget-password">
                  <p>Forgot Password</p>
                </LoginForgetPasswordLink>
              </LoginRememberMeAndForgetPasswordContainer>

              <LoginLoginButtonContainer>
                <LoginLoginButton type="submit">
                  <p>Login</p>
                </LoginLoginButton>
              </LoginLoginButtonContainer>

              <LoginDontHaveAccountContainer>
                <p>Don't have an account?</p>
                <LoginSignUpLink href="/signup">
                  <p>SignUp</p>
                </LoginSignUpLink>
              </LoginDontHaveAccountContainer>

              <DividerContainer>
                <Divider1 />
                <OrLoginWithContainer>Or login with</OrLoginWithContainer>
                <Divider2 />
              </DividerContainer>

              <GoogleSignUpButton role="button" onClick={googleSignIn}>
                <ContinueWithText>Continue with</ContinueWithText>
                <GoogleIcon src={SVG.GoogleIcon} />
              </GoogleSignUpButton>
            </form>
          </FormContainer>
        </FormAndTitleContainer>
        <Copyright />
      </FormAndCopyrightContainer>
      <DesignContainer>
        <AuthDesignImage src={SVG.AuthDesignSection} alt="AuthDesignImage" />
      </DesignContainer>
      <ToastContainer />
    </Container>
  )
}

export default connect(mapStateToProps, { login, loginWithGoogle })(memo(LoginPage))
