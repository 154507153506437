import styled from 'styled-components'

export const ForgetPassFormInputsContainer = styled.div`
  margin-top: 6%;
`

export const ForgetPassSubmitButtonContainer = styled.div`
  margin-top: 35px;
`

export const ForgetPassSubmitButton = styled.button`
  background-color: #8dd3bb;
  padding: 15px;
  font-size: 1.1rem;
  width: 100%;
  border: none;
  font-weight: 600;
  border-radius: 5px;
`
