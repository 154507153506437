export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS'
export const GET_MESSAGES_FAIL = 'GET_MESSAGES_FAIL'

export const SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS'
export const SEND_MESSAGE_FAIL = 'SEND_MESSAGE_FAIL'

export const SOCKET_CONNECTED = 'SOCKET_CONNECTED'
export const SOCKET_DISCONNECTED = 'SOCKET_DISCONNECTED'

export const USER_TYPING = 'USER_TYPING'
export const USER_NOT_TYPING = 'USER_NOT_TYPING'

export const UPDATE_SELECTED_CHAT = 'UPDATE_SELECTED_CHAT'

export const UPDATE_MESSAGES = 'UPDATE_MESSAGES'

export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS'

export const GET_ALL_CHATS_SUCCESS = 'GET_ALL_CHATS_SUCCESS'
export const GET_ALL_CHATS_FAIL = 'GET_ALL_CHATS_FAIL'

export const GET_CREATE_CHAT_SUCCESS = 'GET_CREATE_CHAT_SUCCESS'
export const GET_CREATE_CHAT_FAIL = 'GET_CREATE_CHAT_FAIL'

export const CLEAR_CHATS = 'CLEAR_CHATS'

export const CLEAR_MESSAGES = 'CLEAR_MESSAGES'

export const SET_FETCH_AGAIN = 'SET_FETCH_AGAIN'

export const EMIT_STOP_TYPING = 'EMIT_STOP_TYPING'
export const EMIT_TYPING = 'EMIT_TYPING'

export const ON_MESSAGE_RECEIVED = 'ON_MESSAGE_RECEIVED'
export const OFF_MESSAGE_RECEIVED = 'OFF_MESSAGE_RECEIVED'

export const ON_TYPING_EVENT = 'ON_TYPING_EVENT'
export const ON_STOP_TYPING_EVENT = 'ON_STOP_TYPING_EVENT'

export const OFF_TYPING_EVENT = 'OFF_TYPING_EVENT'
export const OFF_STOP_TYPING_EVENT = 'OFF_STOP_TYPING_EVENT'

export const DISCONNECT_SOCKET = 'DISCONNECT_SOCKET'


export const DEFAULT_STATE = 'DEFAULT_STATE'
