import React from 'react';
import './Copyright.css'
const Copyright = () => {
    return (
        <div className="LoginCopyrightTextContainer">
          <p className="LoginCopyrightText">
            &copy; 2024 <span className="LoginTravmigozCopyrightText">Travmigoz</span>. All Rights Reserved
          </p>
        </div>
    );
};

export default Copyright;